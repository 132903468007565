import Head from 'next/head';
import Link from 'next/link';
import { PageWithLayout } from '../../../lib/types/page';

const NotFoundPage: PageWithLayout = () => {
  return (
    <>
      <Head>
        <title>Page Not Found | Koolumbus</title>
      </Head>

      <div className="flex-shrink-0 flex justify-center mt-16">
        <Link href="/" className="inline-flex">
          <span className="sr-only">Koolumbus</span>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img className="h-20 w-auto" src="/assets/images/logo/logo.png" alt="logo" />
        </Link>
      </div>

      <div className="py-16 px-8">
        <div className="text-center">
          <p className="text-sm font-semibold text-primary uppercase tracking-wide">404 error</p>

          <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
            Page not found.
          </h1>

          <p className="mt-2 text-base text-gray-500">
            Sorry, we couldn’t find the page you’re looking for.
          </p>

          <div className="mt-6">
            <Link href="/" className="text-base font-medium text-primary hover:text-primary-600">
              Go back home<span aria-hidden="true"> &rarr;</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
